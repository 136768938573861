// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Links$SystemaFr from "../utils/Links";
import * as Utils$SystemaFr from "../utils/Utils";
import * as Gatsby$SystemaFr from "../utils/Gatsby";
import * as Layout$SystemaFr from "../components/Layout";
import * as Scripts$SystemaFr from "../utils/Scripts";
import * as Markdown$SystemaFr from "../components/Markdown";

import "../assets/css/home.css"
;

var component = ReasonReact.statelessComponent("Home");

function make(data, _children) {
  var page = {
    content: data.page.html
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (_self) {
              return Curry._1(Scripts$SystemaFr.Mdl.initializeTooltips, /* () */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Layout$SystemaFr.make(undefined, "home-layout", [React.createElement("div", {
                                    className: "content"
                                  }, React.createElement("div", {
                                        className: "mdl-grid"
                                      }, React.createElement("div", {
                                            className: "mdl-cell mdl-cell--2-col mdl-cell--hide-tablet mdl-cell--hide-phone"
                                          }), React.createElement("div", {
                                            className: "mdl-components mdl-cell mdl-cell--8-col"
                                          }, React.createElement("p", {
                                                className: "mdl-typography--headline"
                                              }, Utils$SystemaFr.text("Cours de Systema à Paris avec Khosrow Helly")))), React.createElement("div", {
                                        className: "home-ribbon mdl-grid"
                                      }, React.createElement("div", {
                                            className: "mdl-cell mdl-cell--2-col mdl-cell--hide-tablet mdl-cell--hide-phone"
                                          }), React.createElement("div", {
                                            className: "mdl-components mdl-cell mdl-cell--8-col"
                                          }, React.createElement("div", {
                                                className: "mdl-typography--title"
                                              }, ReasonReact.element(undefined, undefined, Markdown$SystemaFr.make(undefined, page.content, [])), ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make(Links$SystemaFr.library, undefined, undefined, undefined, undefined, "mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect", undefined, undefined, undefined, undefined, [Utils$SystemaFr.text("Découvrir le Systema")]))))), React.createElement("div", {
                                        className: "background-legend",
                                        id: "background-legend"
                                      }, React.createElement("a", {
                                            className: "mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect",
                                            href: "https://fr.wikipedia.org/wiki/Les_Cosaques_zaporogues_%C3%A9crivant_une_lettre_au_sultan_de_Turquie",
                                            rel: "noopener",
                                            target: "_blank"
                                          }, React.createElement("div", {
                                                className: "material-icons md-light"
                                              }, Utils$SystemaFr.text("brush")))), React.createElement("div", {
                                        className: "mdl-tooltip mdl-tooltip--large mdl-tooltip--left",
                                        htmlFor: "background-legend"
                                      }, React.createElement("em", undefined, Utils$SystemaFr.text("Les Cosaques zaporogues écrivant une lettre au sultan de Turquie")), React.createElement("br", undefined), Utils$SystemaFr.text("Ilia Répine, 1880-1891")))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var $$default = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(jsProps.data, jsProps.children);
      }));

export {
  component ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
