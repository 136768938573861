import Home from '../templates/Home.re'
import { graphql } from 'gatsby'

export default Home

// Markdown query
export const query = graphql`
{
  page: markdownRemark (
    fields: {
      sourceInstanceName: { eq: "pages" },
      slug: { eq: "/home/" }
    }
  ) {
    html
  }
}
`

// Strapi query
// export const query = graphql`
// {
  // strapiSnippets(name: { eq: "systemaDefinition" }) {
    // name
    // content {
      // childMarkdownRemark {
        // html
      // }
    // }
  // }
// }
// `
